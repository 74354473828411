import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { useLanguage } from "../Hooks/useLanguage";
import ITLang from '../Images/lang-it.png';
import ENLang from '../Images/lang-en.png';

export default () => {
    const { language, changeLanguage } = useLanguage();

    return (
        <Box sx={{
            textAlign: "center",
            marginTop: 2,
            marginBottom: 2
        }}>
        { language === "it" ? 
            <IconButton
                aria-label="English"
                onClick={() => changeLanguage("en")}
                sx={{
                    background: `url(${ENLang}) no-repeat center`,
                    backgroundSize: "30px 30px",
                    height: "25px",
                    width: "25px"
                }}
            /> : 
            <IconButton
                aria-label="Italian"
                onClick={() => changeLanguage("it")}
                sx={{
                    background: `url(${ITLang}) no-repeat center`,
                    backgroundSize: "30px 30px",
                    height: "25px",
                    width: "25px"
                }}
            />
        }
        </Box>
    );
}