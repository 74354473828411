import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { config } from '../config';
import moment from 'moment';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] =  useState(JSON.parse(window.localStorage.getItem('lang')) || config.language || navigator?.language?.split('-')[0] );

    useEffect(() => {
        moment.locale(language);
        window.localStorage.setItem('lang', JSON.stringify(language));
	}, [language]);

    const changeLanguage = async (lang) => {
        setLanguage(lang);
        moment.locale(lang);
    };

    const value = useMemo(
        () => ({
            language,
            changeLanguage
        }),
        [language]
    );

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => {
    return useContext(LanguageContext);
};