import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { LanguageProvider } from "./Hooks/useLanguage";
import { AuthProvider } from "./Hooks/useAuth";
import { ErrorProvider } from "./Hooks/useError";

import App from "./App";
import theme from './theme';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<BrowserRouter>
		<LanguageProvider>
			<AuthProvider>
				<ErrorProvider>
					<ThemeProvider theme={theme}>
						<App />
					</ThemeProvider>
				</ErrorProvider>
			</AuthProvider>
		</LanguageProvider>
	</BrowserRouter>
);