import { Box, Typography, Button, Switch } from '@mui/material';
import { useState } from 'react';

const Cookieblock = ({
    list,
    cookiesData,
    handleChangeConsent,
    cookieConsent
}) => {
    const [expand, setExpand] = useState(false)

    return <Box sx={{ borderBottom: 1, borderColor: "grey.500", pb: 2, mb: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>{cookiesData.name}</Typography>
            { cookiesData.id === "necessary" ? <Switch label={cookiesData.name} checked={true} readOnly={true} /> : <Switch label={cookiesData.name} checked={cookieConsent[cookiesData.id] || false} onChange={(e) => handleChangeConsent(cookiesData.id, e.target.checked)} /> }
        </Box>
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: cookiesData.description }}></Typography>
        <Button onClick={() => setExpand(!expand)}>+ Dettagli</Button>
        { expand && <Box>
            { list.map((item, idx) => <Box key={`list-${cookiesData.id}-${idx}`} sx={{ border: 1, borderColor: "grey.500", bgcolor: "grey.200", p: 1, borderRadius: "5px", mb: 2 }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>{item.name}</Typography>
                <Typography variant="body2">{item.description}</Typography>
                <Typography variant="body2">Scadenza: {item.expires}</Typography>
                <Typography variant="body2">Tipo: {item.typology}</Typography>
                <Typography variant="body2">Dati inviati in: {item.location}</Typography>
            </Box> ) }
        </Box> }
    </Box>
}

export default Cookieblock