import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		header: {
			background: "#333",
			foreground: "white"
		},
		footer: {
			background: "#333",
			foreground: "white"
		}
	},
});

export default theme;