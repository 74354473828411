import React from 'react';
import { Link as RouterLink, useLocation } from "react-router-dom";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { config, modules } from "../../config";
import i18n from './i18n';
import { useAuth } from "../../Hooks/useAuth";
import { useLanguage } from "../../Hooks/useLanguage";
import { blue } from '@mui/material/colors';

const PersonalMenu = () => {
    const location = useLocation();
    const { logout, userDetails } = useAuth();
    const { language } = useLanguage();
    const __ = i18n[language];

    const profileList = [
        {
            id: 'me',
            link: `/${config.route.root.employee}/${config.route.employee.profile}`,
            text: __['profile']
        },
        {
            id: 'updatepassword',
            link: `/${config.route.root.employee}/${config.route.employee.updatePassword}`,
            text: __['update-password']
        },
    ]

    const profileListFiltered = profileList.filter(element => modules.profile[element.id])

    return (
        <>
        <nav aria-label={__["personal-menu"]}>
            <List sx={{
                '> li': {
                    padding: "2px 5px",
                    '> a, > .MuiListItemButton-root' : {
                        borderRadius: "5px",
                        "span": {fontWeight: "300"},
                        '&:hover span' : {fontWeight: "400"}
                    }
                },
                '.Mui-selected' : {
                    color: blue[600],
                    'span' : {
                        fontWeight: '500'
                    }
                }
            }}>
            {profileListFiltered && profileListFiltered.map((item) => (
                <ListItem key={item.id} disablePadding>
                    <ListItemButton dense selected={location.pathname.indexOf(item.link) > -1} component={RouterLink} to={item.link}>
                        <ListItemText primary={item.text} />
                    </ListItemButton>
                </ListItem>
            ))}
                <ListItem disablePadding>
                    <ListItemButton dense onClick={() => logout(`/${config.route.root.employee}/${config.route.employee.login}`)}>
                        <ListItemText primary={__["logout"]} />
                    </ListItemButton>
                </ListItem>
            </List>
        </nav>
        </>
    )
};

export default PersonalMenu;