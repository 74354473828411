import { createContext, useContext, useEffect, useMemo, useState } from "react";

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
    const [globalError, setGlobalError] = useState(null);

    const value = useMemo(
        () => ({
            globalError,
            setGlobalError
        }),
        [globalError]
    );

    return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};

export const useError = () => {
    return useContext(ErrorContext);
};