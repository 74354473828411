const i18n = {
    en: {
        "validation-email": "Email is required",
        "validation-email-format": "Email is not valid",
        "validation-firstname": "Name is required",
        "validation-firstname-format": "Name must contain only letters",
        "validation-location": "Location is required",
        "validation-lastname": "Lastname is required",
        "validation-lastname-format": "Lastname must contain only letters",
        "validation-role": "Role is required",
        "validation-taxcode-format": "Taxcode is not valid",
        "validation-taxcode-length": "Taxcode must be 16 characters long",
        "validation-phone-format": "Phone is not valid",
        "validation-phone-length": "Phone must be 10 digits long",
        "validation-oldpassword": "Current password is mandatory",
        "validation-password": "Password is mandatory",
        "validation-password-confirm": "Confirm new password",
        "validation-password-match": "Passwords do not match",
        "validation-password-length": "Password must have at least 8 characters",
        "validation-password-chars": "Password must contains at least one lowercase letter, one uppercase letter, one digit and one special character",
        "validation-auth": "Two-factor authentication is required",
        "validation-sms": "Verification code is required",
        "validation-ordernumber": "Order number is mandatory"
    },
    it: {
        "validation-email": "L'email è obbligatoria",
        "validation-email-format": "L'email non è valida",
        "validation-firstname": "Il nome è obbligatorio",
        "validation-firstname-format": "Il nome può contenere solo lettere",
        "validation-location": "La sede è obbligatoria",
        "validation-lastname": "Il cognome è obbligatorio",
        "validation-lastname-format": "Il cognome può contenere solo lettere",
        "validation-role": "Il ruolo è obbligatorio",
        "validation-taxcode-format": "Il codice fiscale non è valido",
        "validation-taxcode-length": "Il codice fiscale deve essere di 16 caratteri",
        "validation-phone-format": "Il numero di telefono non è valido",
        "validation-phone-length": "Il numero di telefono deve essere di 10 cifre",
        "validation-oldpassword": "La password corrente è obbligatoria",
        "validation-password": "La password è obbligatoria",
        "validation-password-confirm": "Conferma la nuova password",
        "validation-password-match": "Le password non corrispondono",
        "validation-password-length": "La password deve essere lunga almeno 8 caratteri",
        "validation-password-chars": "La password deve contenere almeno una lettere minuscola, una lettera maiuscola, un numero ed un carattere speciale",
        "validation-auth": "Two-factor authentication è obbligatorio",
        "validation-sms": "Il codice di verifica è obbligatorio",
        "validation-ordernumber": "Il numero d'ordine è obbligatorio",
        "validation-businessname": "La ragione sociale è obbligatoria",
        "validation-businessname-format": "La ragione sociale non è valida",
        "validation-vatnumber": "La partita iva è obbligatoria",
        "validation-vatnumber-format": "La partita iva non è valida",
        "validation-billingcode-format": "Il codice univoco di fatturazione non è valido",
        "validation-billingcode-length": "Il codice univoco di fatturazione deve essere compreso tra 6 e 7 caratteri"
    }
};

export default i18n;