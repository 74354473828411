import i18n from './i18n';
import moment from "moment";

const manageGlobalErrorMessage = (status) => {
    const messages = {
        500: 'Il sistema non è stato in grado di completare la tua richiesta, riprova più tardi.',
        400: 'I dati inviati non sono completi.',
        401: 'I dati inseriti non sono corretti.',
        404: 'La risorsa che si sta cercando non esiste.',
        409: 'Non è consentito salvare una risorsa duplicata.'
    };

    return {
        type: 'error',
        status,
        message: messages[status]
    }
}

const handleAPIRequest = ({
        url,
        method,
        data,
        headers,
        files,
        responseType,
        callback,
        errorcallback
    }) => {
    const configObj = {
        method
    };
    if( data ) configObj.body = JSON.stringify(data);
    else if( files ) configObj.body = files;
    headers ? configObj.headers = headers : configObj.headers = {};
    if(!files && !responseType) configObj.headers['Content-Type'] = 'application/json';

    fetch(url, configObj)
    .then(response => {
        const status = response.status.toString();
        if( status.indexOf('5') === 0 || status.indexOf('4') === 0 ) {
            return manageGlobalErrorMessage(status);
        } else if( responseType === "blob" ) {
            return response.blob();
        } else
            return response.json();
    })
    .then(d => {
        if( d.type === 'error' )
            typeof errorcallback === 'function' && errorcallback(d)
        else if( typeof callback === 'function' ) callback(d)
    })
    .catch((error) => {
        console.error('Error',error);
    });
}

const handleAPIFetch = ({
    url,
    method,
    data,
    headers,
    files,
    responseType
}) => {
    const configObj = {
        method
    };
    if( data ) configObj.body = JSON.stringify(data);
    else if( files ) configObj.body = files;
    headers ? configObj.headers = headers : configObj.headers = {};
    if(!files && !responseType) configObj.headers['Content-Type'] = 'application/json';

    return fetch(url, configObj)
}

const retrieveDataFromStorage = ({
    objToRetrieve,
    dataId,
    stateCallback,
    fetchCallback
}) => {
    if( objToRetrieve  && window.localStorage.getItem(objToRetrieve) ) {
        try {
            const obj = JSON.parse(window.localStorage.getItem(objToRetrieve))
            if( obj[dataId] && moment.unix(obj[dataId].expires) > moment() && typeof stateCallback == 'function' )
                stateCallback(obj[dataId].data);
            else if( typeof fetchCallback == 'function' )
                fetchCallback();
        } catch (error) {
            console.log('cannot retrieve from storage');
            console.log(error)
            fetchCallback()
        }
    } else {
        fetchCallback()
    }
}

const saveDataInStorage = ({
    objToSave,
    dataId,
    data,
    minutes = 3
}) => {
    const jsonData = {
        [dataId]: {
            data,
            expires: moment().add(minutes, 'minutes').unix()
        }
    }
    try {
        let storedData = window.localStorage.getItem(objToSave);
        if( storedData ) {
            storedData = JSON.parse(storedData);
            let newStoredData = {...storedData, ...jsonData};
            window.localStorage.setItem(objToSave, JSON.stringify(newStoredData))
        } else {
            window.localStorage.setItem(objToSave, JSON.stringify(jsonData))
        }
    } catch (error) {
        console.log('cannot save into storage')
        console.log(error)
    }
}

const formValidation = ({
    language,
    currentPassword,
    optionals
}) => {
    const __ = i18n[language];
    return {
        email: {
            required: optionals?.email ? false : __["validation-email"],
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: __["validation-email-format"]
            }
        },
        firstname: {
            required: __["validation-firstname"],
            pattern: {
                value: /^[A-Za-z\u00C0-\u00F6\u00F8-\u00FF\'\-\s]+$/i,
                message: __["validation-firstname-format"]
            }
        },
        location: {
            required: __["validation-location"]
        },
        lastname: {
            required: __["validation-lastname"],
            pattern: {
                value: /^[A-Za-z\u00C0-\u00F6\u00F8-\u00FF\'\-\s]+$/i,
                message: __["validation-lastname-format"]
            },
        },
        role: { required: __["validation-role"] },
        taxcode: {
            minLength: {
                value: 16,
                message: __["validation-taxcode-length"]
            },
            maxLength: {
                value: 16,
                message: __["validation-taxcode-length"]
            },
            pattern: {
                value: /^[A-Za-z0-9]+$/i,
                message: __["validation-taxcode-format"]
            },
        },
        phone: {
            minLength: {
                value: 10,
                message: __["validation-phone-length"]
            },
            pattern: {
                value: /^[0-9]+$/i,
                message: __["validation-phone-format"]
            }
        },
        oldpassword: {
            required: __["validation-oldpassword"],
        },
        password: {
            required: __["validation-password"],
            minLength: {
                value: 8,
                message: __["validation-password-length"]
            },
            validate: value => {
                return new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$").test(value) || __["validation-password-chars"]
            }
        },
        passwordconfirm: {
            required: __["validation-password-confirm"],
            validate: value => value === currentPassword || __["validation-password-match"]
        },
        twoFactorToken: { required: __["validation-auth"] },
        smsToken: { required: __["validation-sms"] },
        ordernumber: { required: __["validation-ordernumber"] },
        businessname: {
            required: __["validation-businessname"],
            pattern: {
                value: /^[A-Za-z0-9\u00C0-\u00F6\u00F8-\u00FF\'\-\.\s]+$/i,
                message: __["validation-businessname-format"]
            }
        },
        vatnumber: {
            required: optionals?.vatnumber ? false : __["validation-vatnumber"],
            pattern: {
                value: /^[A-Za-z0-9]+$/i,
                message: __["validation-vatnumber-format"]
            }
        },
        billingcode: {
            pattern: {
                value: /^[A-Za-z0-9]+$/i,
                message: __["validation-billingcode-format"]
            },
            minLength: {
                value: 6,
                message: __["validation-billingcode-length"]
            },
            maxLength: {
                value: 7,
                message: __["validation-billingcode-length"]
            },
        }
    }
}

const isValidURL = (url) => {
    const validRegex = /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return validRegex.test(url);
}

const isValidEmail = (email) => {
    const validRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return validRegex.test(email);
}

const slugify = (value, separator = "-") => {
    return value
        .toString()
        .normalize('NFD')                   // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9\- ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, separator)
        .replace(/\-+/g, separator)
}

const getFileFromUrl = async(url, name, defaultType = 'image/jpeg') => {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
        type: data.type || defaultType,
    })
}

const isValidHttpUrl = (value) => {
    let url;
  
    try {
        url = new URL(value);
    } catch (_) {
        return false;  
    }

    return url.protocol === "http:" || url.protocol === "https:"
}

const utils = {
    handleAPIRequest,
    handleAPIFetch,
    retrieveDataFromStorage,
    saveDataInStorage,
    formValidation,
    isValidURL,
    isValidEmail,
    slugify,
    getFileFromUrl,
    isValidHttpUrl
}

export default utils;