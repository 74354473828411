import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogActions, Tabs, Tab, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Cookies from 'js-cookie';
import { config } from '../../config';
import utils from '../../utils';
import Cookieblock from './Cookieblock';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ pt: 3, maxHeight: "400px", overflow: "scroll"  }}>{children}</Box>
        )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const CustomButton = styled(Button)(({theme}) =>({
    width: "100%",
    textTransform: "capitalize",
    ":not(:last-of-type)" : {
        border: "2px solid",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "0.5rem",
        },
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: "0 !important",
        marginRight: "0 !important"
    },
}));

function CookieConsent() {
    const [cookieBanner, setCookieBanner] = useState(false);
    const [cookieTabValue, setCookieTabValue] = useState(0);
    const [cookieConsent, setCookieConsent] = useState({})
    const [settings, setSettings] = useState({})

    useEffect(() => {
        utils.handleAPIRequest({
            url: config.api.site.cookies,
            method: "GET",
            callback: d => {
                setSettings(d)
            }
        })
        if( Cookies.get("cookieConsent") ) {
            setCookieConsent(JSON.parse(Cookies.get("cookieConsent")))
        } else {
            setCookieBanner(true);
        }
    }, []);

    const handleChangeTab = (event, value) => {
        setCookieTabValue(value);
    };

    const confirmCookie = (type) => {
        const currentConsent = {...cookieConsent};
        const list = Object.keys(settings?.cookielist);
        currentConsent.necessary = true
        if( type === "all" ) {
            list.forEach(i => {
                const idx = cookiesData[i].id
                currentConsent[idx] = true
            })
            setCookieConsent(currentConsent)
        } else if( type === "none" ) {
            list.filter(i => i !== "1").forEach(i => {
                const idx = cookiesData[i].id
                currentConsent[idx] = false
            })
            setCookieConsent(currentConsent)
        }
        Cookies.set("cookieConsent", JSON.stringify(currentConsent), { expires: 365 });
        setCookieBanner(false);
        setCookieTabValue(0);
    }

    const handleChangeConsent = (type, value) => {
        setCookieConsent(prevState => {
            return {
                ...prevState,
                [type]: value
            }
        })
    }

    const cookiesData = {
        1: {
            id: "necessary",
            name: "Necessari",
            description: settings?.cookiecategories?.necessary || ""
        },
        2: {
            id: "preferences",
            name: "Preferenze",
            description: settings?.cookiecategories?.preferences || ""
        },
        3: {
            id: "statistics",
            name: "Statistiche",
            description: "I cookie statistici aiutano i proprietari del sito web a capire come i visitatori interagiscono con i siti raccogliendo e trasmettendo informazioni in forma anonima."
        },
        4: {
            id: "marketing",
            name: "Marketing",
            description: settings?.cookiecategories?.marketing || ""
        },
        6: {
            id: "unclassified",
            name: "Non classificati",
            description: ""
        }
    }

    return <>
    <Dialog
        open={cookieBanner}
        sx={{
            "& .MuiDialog-paper" : {
                m: "10px"
            }
        }}
    >
        <DialogContent sx={{ p: 2 }}>
            <Tabs value={cookieTabValue} onChange={handleChangeTab} sx={{
                "& .MuiTabs-flexContainer": { overflow: "scroll" },
                "& button": {
                    fontWeight: "bold",
                    p: "0.5rem",
                    "&:first-of-type, &:last-of-type": {
                        pl: 0,
                        pr: 0
                    }
                }
            }}>
                <Tab label="Consenso" {...a11yProps(0)} />
                <Tab label="Dettagli" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={cookieTabValue} index={0}>
                <Box sx={{ fontSize: "14px", lineHeight: "1.4" }} dangerouslySetInnerHTML={{ __html: settings?.cookieconsent }} />
            </TabPanel>
            <TabPanel value={cookieTabValue} index={1}>

                { settings?.cookielist && Object.keys(settings?.cookielist).map(i => <Cookieblock
                    key={`cookieblock-${i}`}
                    list={settings?.cookielist[i]}
                    cookiesData={cookiesData[i]}
                    handleChangeConsent={handleChangeConsent}
                    cookieConsent={cookieConsent}
                />) }

            </TabPanel>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
            <CustomButton onClick={ () => confirmCookie('none') } variant="outlined">Rifiuta</CustomButton>
            { cookieTabValue === 1 && <CustomButton onClick={ () => confirmCookie('selected') } variant="outlined">Accetta selezionati</CustomButton> }
            { cookieTabValue !== 1 && <CustomButton onClick={ () => setCookieTabValue(1) } variant="outlined">Personalizza</CustomButton> }
            <CustomButton onClick={ () => confirmCookie('all') } variant="contained">Accetta tutti</CustomButton>
        </DialogActions>
    </Dialog>
    </>
}

export default CookieConsent;