import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from "react-router-dom";
import moment from 'moment';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import HomeIcon from '@mui/icons-material/Home';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import PeopleIcon from '@mui/icons-material/People';
import PlaceIcon from '@mui/icons-material/Place';
import ExpandLess from '@mui/icons-material/ExpandLess';
import HandymanIcon from '@mui/icons-material/Handyman';
import FeedIcon from '@mui/icons-material/Feed';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import GavelIcon from '@mui/icons-material/Gavel';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ArticleIcon from '@mui/icons-material/Article';
import LinkIcon from '@mui/icons-material/Link';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { config, modules } from "../../config";
import i18n from './i18n';
import { useAuth } from "../../Hooks/useAuth";
import { useLanguage } from "../../Hooks/useLanguage";
import { blue } from '@mui/material/colors';
import { Box, Collapse } from '@mui/material';

const AccordionMenu = ({
    menu,
    open,
    expandedMenu,
    setExpandedMenu
}) => {
    const location = useLocation();
    const { permissions } = useAuth();

    const handleClick = () => {
        setExpandedMenu(prevState => prevState !== menu.id || !open ? menu.id : null)
    }

    const menuListFiltered = permissions && menu.items.filter(element => (modules.employee[element.id] && permissions[element.id]?.allowed) || (modules.employee[menu.id] && permissions[menu.id]?.allowed));

    return menuListFiltered.length > 0 && <ListItem dense disablePadding sx={{ bgcolor: `${expandedMenu === menu.id ? 'rgba(231, 235, 240, 0.3)' : 'auto'}`, display: "block" }}>
        <ListItemButton
            onClick={handleClick}
            sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'left',
                px: "10px",
            }}
        >
            <ListItemIcon sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                }}>{ menu.icon }</ListItemIcon>
            <ListItemText primary={ menu.name } sx={{ opacity: open ? 1 : 0 }} />
            { expandedMenu === menu.id && open && <ExpandLess /> }
        </ListItemButton>
        <Collapse in={expandedMenu === menu.id && open}>
            <List disablePadding>
            { menuListFiltered.map( item => <ListItem key={ item.id } dense disablePadding>
                    <ListItemButton
                        component={RouterLink}
                        to={item.link}
                        selected={location.pathname === item.link }
                    >
                        <ListItemText primary={ item.text } />
                    </ListItemButton>
                </ListItem>
            )}
            </List>
        </Collapse>
    </ListItem>
}

const PortalMenu = ({
    open,
    expandedMenu,
    setExpandedMenu
}) => {
    const location = useLocation();
    const { userDetails } = useAuth();
    const { language } = useLanguage();
    const __ = i18n[language];

    const menus = [
        {
            name: __['administration'],
            icon: <BadgeIcon fontSize="small" />,
            id: 'administration-menu',
            items: [
                {
                    id: 'registry',
                    link: `/${config.route.root.employee}/${config.route.employee.registry}`,
                    text: __['registry'],
                    divider: true
                },
                {
                    id: 'appointments',
                    icon: <AccessAlarmIcon/>,
                    link: `/${config.route.root.employee}/${config.route.employee.appointments}`,
                    text: __['appointments']
                },
                {
                    id: 'payments',
                    link: `/${config.route.root.employee}/${config.route.employee.payments}`,
                    text: __['payments']
                },
                {
                    id: 'employees',
                    icon: <PeopleIcon/>,
                    link: `/${config.route.root.employee}/${config.route.employee.employees}`,
                    text: __['employees']
                },
                {
                    id: 'holidays',
                    icon: <EventAvailableIcon/>,
                    link: `/${config.route.root.employee}/${config.route.employee.holidays}`,
                    text: __['holidays']
                },
                {
                    id: 'locations',
                    icon: <PlaceIcon/>,
                    link: `/${config.route.root.employee}/${config.route.employee.locations}`,
                    text: __['locations']
                },
                {
                    id: 'services',
                    icon: <MedicalServicesIcon/>,
                    link: `/${config.route.root.employee}/${config.route.employee.typologies}`,
                    text: __['services']
                },
                {
                    id: 'users',
                    link: `/${config.route.root.employee}/${config.route.employee.users}`,
                    text: __['users']
                },
                {
                    id: 'reports',
                    link: `/${config.route.root.employee}/${config.route.employee.reports}`,
                    text: __['reports']
                },
                {
                    id: 'coupons',
                    icon: <LocalActivityIcon/>,
                    link: `/${config.route.root.employee}/${config.route.employee.coupons}`,
                    text: __['coupons'],
                    divider: true
                },
                {
                    id: 'settings',
                    link: `/${config.route.root.employee}/${config.route.employee.appointments}/${config.route.employee.settings}`,
                    text: 'Orario lavorativo'
                },
            ]
        },
        {
            name: __['tools'],
            icon: <HandymanIcon fontSize="small" />,
            id: 'tools-menu',
            items: [
                {
                    id: 'scheduler',
                    icon: <CalendarMonthIcon/>,
                    link: `/${config.route.root.employee}/${config.route.employee.scheduler}`,
                    text: __['scheduler']
                },
                {
                    id: 'availabilities',
                    icon: <CalendarMonthIcon/>,
                    link: userDetails.role == 2 ? `/${config.route.root.employee}/${config.route.employee.availabilities}/${userDetails._id}` : `/${config.route.root.employee}/${config.route.employee.availabilities}`,
                    text: __['availabilities']
                }
            ]
        },
        {
            name: "Articoli",
            icon: <ArticleIcon fontSize="small" />,
            id: 'blog',
            items: [
                {
                    id: 'blog-new',
                    link: `/${config.route.root.employee}/${config.route.employee.articles}/${config.route.employee.new}`,
                    text: 'Nuovo Articolo'
                },
                {
                    id: 'blog-list',
                    link: `/${config.route.root.employee}/${config.route.employee.articles}`,
                    text: 'Lista Articoli'
                },
                {
                    id: 'blog-settings',
                    link: `/${config.route.root.employee}/${config.route.employee.articles}/${config.route.employee.settings}`,
                    text: 'Impostazioni'
                }
            ]
        },
        {
            name: "Pagine",
            icon: <NewspaperIcon fontSize="small" />,
            id: 'pages',
            items: [
                {
                    id: 'pages-new',
                    link: `/${config.route.root.employee}/${config.route.employee.pages}/${config.route.employee.new}`,
                    text: 'Nuova Pagina'
                },
                {
                    id: 'pages-list',
                    link: `/${config.route.root.employee}/${config.route.employee.pages}`,
                    text: 'Lista Pagine'
                },
                {
                    id: 'pages-settings',
                    link: `/${config.route.root.employee}/${config.route.employee.pages}/${config.route.employee.settings}`,
                    text: 'Impostazioni'
                }
            ]
        },
        {
            name: "Pagine Legali",
            icon: <GavelIcon fontSize="small" />,
            id: 'legals',
            items: [
                {
                    id: 'legals-new',
                    link: `/${config.route.root.employee}/${config.route.employee.legals}/${config.route.employee.new}`,
                    text: 'Nuova Pagina'
                },
                {
                    id: 'legals-list',
                    link: `/${config.route.root.employee}/${config.route.employee.legals}`,
                    text: 'Lista Pagine'
                },
                {
                    id: 'legals-consent',
                    link: `/${config.route.root.employee}/${config.route.employee.legals}/gestione-consenso`,
                    text: 'Gestione Testi Consenso'
                },
                {
                    id: 'legals-cookies',
                    link: `/${config.route.root.employee}/${config.route.employee.legals}/gestione-cookie`,
                    text: 'Gestione Cookie'
                },
                {
                    id: 'legals-settings',
                    link: `/${config.route.root.employee}/${config.route.employee.legals}/${config.route.employee.settings}`,
                    text: 'Impostazioni'
                }
            ]
        },
        {
            name: "Pagine Affiliate",
            icon: <LinkIcon fontSize="small" />,
            id: 'affiliates',
            items: [
                {
                    id: 'affiliates-new',
                    link: `/${config.route.root.employee}/${config.route.employee.affiliates}/${config.route.employee.new}`,
                    text: 'Nuova Pagina'
                },
                {
                    id: 'affiliates-list',
                    link: `/${config.route.root.employee}/${config.route.employee.affiliates}`,
                    text: 'Lista Pagine'
                },
                {
                    id: 'affiliates-settings',
                    link: `/${config.route.root.employee}/${config.route.employee.affiliates}/${config.route.employee.settings}`,
                    text: 'Impostazioni'
                }
            ]
        },
        {
            name: 'Funnel',
            icon: <AutoAwesomeMotionIcon fontSize="small" />,
            id: 'funnels',
            items: [
                {
                    id: 'funnels-new',
                    link: `/${config.route.root.employee}/${config.route.employee.funnels}/${config.route.employee.new}`,
                    text: "Nuovo Funnel"
                },
                {
                    id: 'funnels-list',
                    link: `/${config.route.root.employee}/${config.route.employee.funnels}`,
                    text: "Lista Funnel"
                },
                {
                    id: 'prospects',
                    link: `/${config.route.root.employee}/${config.route.employee.prospects}`,
                    text: __['prospects']
                },
            ]
        },
        {
            name: 'Landing',
            icon: <FeedIcon fontSize="small" />,
            id: 'landings',
            items: [
                {
                    id: 'landings-new',
                    link: `/${config.route.root.employee}/${config.route.employee.landings}/${config.route.employee.new}`,
                    text: "Nuova Landing"
                },
                {
                    id: 'landings-list',
                    link: `/${config.route.root.employee}/${config.route.employee.landings}`,
                    text: "Lista Landing"
                },
                {
                    id: 'landing-settings',
                    link: `/${config.route.root.employee}/${config.route.employee.landings}/${config.route.employee.settings}`,
                    text: 'Impostazioni'
                }
            ]
        },
        {
            name: __['products'],
            icon: <ShoppingCartIcon fontSize="small" />,
            id: 'products',
            items: [
                {
                    id: 'products-new',
                    link: `/${config.route.root.employee}/${config.route.employee.products}/${config.route.employee.new}`,
                    text: 'Nuovo Prodotto'
                },
                {
                    id: 'products-list',
                    link: `/${config.route.root.employee}/${config.route.employee.products}`,
                    text: 'Lista Prodotti'
                },
                {
                    id: 'products-settings',
                    link: `/${config.route.root.employee}/${config.route.employee.products}/${config.route.employee.settings}`,
                    text: 'Impostazioni'
                }
            ]
        },
        {
            name: 'Uploads',
            icon: <UploadFileIcon fontSize="small" />,
            id: 'uploads',
            items: [
                {
                    id: 'uploads-gallery',
                    link: `/${config.route.root.employee}/${config.route.employee.gallery}`,
                    text: 'Galleria Immagini'
                },
                {
                    id: 'uploads-files',
                    link: `/${config.route.root.employee}/${config.route.employee.files}`,
                    text: 'Librerie CSS & JS'
                },
            ]
        },
        {
            name: 'Componenti',
            icon: <PhotoAlbumIcon fontSize="small" />,
            id: 'multimedia',
            items: [
                {
                    id: 'multimedia-brands-new',
                    link: `/${config.route.root.employee}/${config.route.employee.brands}/${config.route.employee.new}`,
                    text: 'Nuovo Brand'
                },
                {
                    id: 'multimedia-brands',
                    link: `/${config.route.root.employee}/${config.route.employee.brands}`,
                    text: 'Lista Brand'
                },
                {
                    id: 'multimedia-categories-new',
                    link: `/${config.route.root.employee}/${config.route.employee.categories}/${config.route.employee.new}`,
                    text: 'Nuova Categoria'
                },
                {
                    id: 'multimedia-categories',
                    link: `/${config.route.root.employee}/${config.route.employee.categories}`,
                    text: 'Lista Categorie'
                },
                {
                    id: 'multimedia-tags-new',
                    link: `/${config.route.root.employee}/${config.route.employee.tags}/${config.route.employee.new}`,
                    text: 'Nuovo Tag'
                },
                {
                    id: 'multimedia-tags',
                    link: `/${config.route.root.employee}/${config.route.employee.tags}`,
                    text: 'Lista Tag'
                },
                {
                    id: 'multimedia-emails-new',
                    link: `/${config.route.root.employee}/${config.route.employee.emails}/${config.route.employee.new}`,
                    text: 'Nuovo Email Template'
                },
                {
                    id: 'multimedia-emails',
                    link: `/${config.route.root.employee}/${config.route.employee.emails}`,
                    text: 'Lista Email Template'
                },
                {
                    id: 'multimedia-forms-new',
                    link: `/${config.route.root.employee}/${config.route.employee.forms}/${config.route.employee.new}`,
                    text: 'Nuova Form'
                },
                {
                    id: 'multimedia-forms',
                    link: `/${config.route.root.employee}/${config.route.employee.forms}`,
                    text: 'Lista Form'
                },
                {
                    id: 'multimedia-footers-new',
                    link: `/${config.route.root.employee}/${config.route.employee.footers}/${config.route.employee.new}`,
                    text: 'Nuovo Footer'
                },
                {
                    id: 'multimedia-footers',
                    link: `/${config.route.root.employee}/${config.route.employee.footers}`,
                    text: 'Lista Footer'
                },
                {
                    id: 'multimedia-settings',
                    link: `/${config.route.root.employee}/multimedia/${config.route.employee.settings}`,
                    text: 'Impostazioni'
                }
            ]
        },
        {
            name: __['settings'],
            icon: <SettingsIcon fontSize="small" />,
            id: 'settings',
            items: [
                {
                    id: 'settings-site',
                    link: `/${config.route.root.employee}/${config.route.employee.settings}/site`,
                    text: 'Sito'
                }
            ]
        }
    ]

    return (
        <>
        <nav aria-label={__["main-menu"]}>
            <List sx={{
                '.Mui-selected' : {
                    color: blue[600],
                    'span' : {
                        fontWeight: '500'
                    }
                },
                'svg' : {
                    color: blue[600]
                }
            }}>
                <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{
                            minHeight: 40,
                            justifyContent: open ? 'initial' : 'left',
                            px: "10px",
                        }}
                        dense
                        selected={location.pathname.indexOf(config.route.employee.dashboard) > -1}
                        component={RouterLink}
                        to={`/${config.route.root.employee}/${config.route.employee.dashboard}`}
                        onClick={(e) => {
                            if(!open) e.preventDefault()
                            setExpandedMenu(null)
                        }}
                    >
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 2 : 'auto',
                            justifyContent: 'center',
                        }}><HomeIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary={__["dashboard"]} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                { menus.map(menu => <AccordionMenu key={ menu.id } menu={menu} open={open} expandedMenu={expandedMenu} setExpandedMenu={setExpandedMenu} />) }
            </List>
        </nav>
        </>
    )
};

export default PortalMenu;