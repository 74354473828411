import React, { useEffect, useState } from 'react';
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";

import { AppBar, Avatar, Box, CssBaseline, Drawer, IconButton, Link, List, ListItem, ListItemButton, ListItemText, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

import logo from '../Images/logo.png';
import Loading from "../Components/Loading/Loading";
import CookieConsent from '../Components/CookieConsent/CookieConsent';
import { config, modules } from "../config";
import { useAuth } from "../Hooks/useAuth";
import { blue } from '@mui/material/colors';

function Layout({
    width,
    hasMenu,
    hasFooter,
    checkSession,
    content
}) {
    const [menuTarget, setMenuTarget] = useState(null);
    const [menuMainTarget, setMenuMainTarget] = useState(null);
	const location = useLocation();
	const { pathname } = location;
    const menuOpen = Boolean(menuTarget);
    const menuMainOpen = Boolean(menuMainTarget);
    const { logout, token, retrieveUserDetails, userDetails, setting } = useAuth();
    const theme = useTheme();

    useEffect(() => {
        //if(checkSession) retrieveUserDetails();
        retrieveUserDetails();
	}, [token]);

	useEffect(() => {
        if(checkSession && userDetails && userDetails.role) logout();
	}, [userDetails]);

    useEffect(() => {
		handleCloseMenu();
		handleCloseMainMenu()
	}, [pathname])

    const handleClickMenu = (event) => {
        if(menuOpen) handleCloseMenu();
        else {
            handleCloseMainMenu();
            setMenuTarget(event.currentTarget);
        }
    }

    const handleCloseMenu = () => {
        setMenuTarget(null);
    };

    const handleClickMainMenu = (event) => {
        if(menuMainOpen) handleCloseMainMenu();
        else {
            handleCloseMenu();
            setMenuMainTarget(event.currentTarget);
        }
    }

    const handleCloseMainMenu = () => {
        setMenuMainTarget(null);
    };

    const mainLinks = [];

    if( modules.root.site && modules.site.checkout ) {
        mainLinks.push({
            link: `/${config.route.root.checkout}`,
            text: "Prenota"
        })
    }

    if( modules.root.profile && modules.profile.appointments ) {
        mainLinks.push({
            link: `/${config.route.root.user}/${ config.route.user.appointments }`,
            text: "Appuntamenti"
        })
    }

    if( modules.root.profile && userDetails ) {
        mainLinks.push({
            link: `/${config.route.root.user}/${ config.route.user.invoices }`,
            text: "Fatture"
        })
    }

    if( modules.root.profile && modules.profile.reports ) {
        if( !userDetails || userDetails?.settings?.onlinereporting ) {
            mainLinks.push({
                link: `/${config.route.root.user}/${ config.route.user.reports }`,
                text: "Referti"
            })
        }
    }

    const menuLinks = [];

    if( modules.root.profile && userDetails ) {
        menuLinks.push({
            link: `${config.route.root.user}/${config.route.user.profile}`,
            text: "Profilo"
        })
        menuLinks.push({
            link: `${config.route.root.user}/${config.route.user.preferences}`,
            text: "Preferenze"
        })
        menuLinks.push({
            link: `${config.route.root.user}/${config.route.user.updatePassword}`,
            text: "Aggiorna password"
        })
        menuLinks.push({
            action: () => logout(`/${config.route.root.user}/${config.route.user.login}`),
            icon: <LogoutIcon sx={{ mr: 1 }} fontSize="small" />,
            text: "Esci"
        })
    }

    return (
		<Box sx={{
            "@page": {
                size: "auto",
                margin: "0mm"
            }
        }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					zIndex: "900",
                    displayPrint: "none"
				}}
			>
				<Toolbar
					sx={{
						bgcolor: theme.palette.header.background,
						height: "64px",
                        display: "flex",
                        justifyContent: "space-between"
					}}
				>
                    { hasMenu && <>
                        <List sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {
                            mainLinks?.map((item, idx) => <ListItem key={`${idx}-mainlinks`} disablePadding>
                                <ListItemButton sx={{ color: theme.palette.header.foreground , "&:hover, &:focus": { textDecoration: "underline", bgcolor: `${theme.palette.header.background} !important` }, "&.Mui-selected": { bgcolor: `${theme.palette.header.background} !important` } }} dense selected={location.pathname.indexOf(item.link) > -1} component={RouterLink} to={item.link}>
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>)
                        }
                        </List>
                        <Box sx={{ display: { md: 'none' } }}>
                            <IconButton
                                sx={{ width: 40, height: 40, mr: 2 }}
                                onClick={handleClickMainMenu}
                                aria-controls={menuMainOpen ? 'main-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={menuMainOpen ? 'true' : undefined}
                            >
                                <MenuIcon fontSize="large" sx={{ color: theme.palette.header.foreground }} />
                            </IconButton>
                        </Box>
                        <Drawer
                            anchor="left"
                            id="main-menu"
                            open={menuMainOpen}
                            onClose={handleCloseMainMenu}
                            ModalProps={{
                                keepMounted: true
                            }}
                            sx={{
                                zIndex: "899"
                            }}
                        >
                            <Box sx={{ width: 240 }}>
                                <Toolbar sx={{ mb: 2 }} />
                                <List sx={{
                                    '> li': {
                                        padding: "2px 5px",
                                        '> a, > .MuiListItemButton-root' : {
                                            borderRadius: "5px",
                                            "span": {fontWeight: "300"},
                                            '&:hover span' : {fontWeight: "400"}
                                        }
                                    },
                                    '.Mui-selected' : {
                                        color: blue[600],
                                        'span' : {
                                            fontWeight: '500'
                                        }
                                    }
                                }}>
                                {
                                    mainLinks?.map((item, idx) => <ListItem key={`${idx}-menulinks`} disablePadding>
                                        <ListItemButton dense selected={location.pathname.indexOf(item.link) > -1} component={RouterLink} to={item.link}>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>)
                                }
                                </List>
                            </Box>
                        </Drawer>
                    </> }
					<Box sx={{
                        width: {xs: "80px", md: "120px"},
                        position: "absolute",
                        left:{xs: "calc(50% - 40px)", md: "calc(50% - 60px)"}
                    }}>
                        { hasMenu ? <Link component={RouterLink} to={`/${config.route.root.user}`}><img width="120px" height="40px" src={logo} alt="" /></Link> : <img width="120px" height="40px" src={logo} alt="" /> }
                    </Box>
                    { hasMenu && <>
                        { !userDetails && <Link variant="body2" sx={{ color: theme.palette.header.foreground, textDecoration: "none", "&:hover": { textDecoration: "underline" } }} component={RouterLink} to={`${config.route.root.user}/${config.route.user.login}`}>Accedi</Link> }
                        { userDetails && <>
                        <Box>
                            <IconButton
                                sx={{ width: 40, height: 40 }}
                                onClick={handleClickMenu}
                                aria-controls={menuOpen ? 'personal-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={menuOpen ? 'true' : undefined}
                            >
                                <Avatar>{userDetails?.data?.firstname ? `${userDetails?.data?.firstname?.slice(0,1)}${userDetails?.data?.lastname?.slice(0,1)}` : <PersonIcon />}</Avatar>
                            </IconButton>
                        </Box>
                        <Drawer
                            anchor="right"
                            id="personal-menu"
                            open={menuOpen}
                            onClose={handleCloseMenu}
                            ModalProps={{
                                keepMounted: true
                            }}
                            sx={{
                                zIndex: "899"
                            }}
                        >
                            <Box sx={{ width: 240 }}>
                                <Toolbar sx={{ mb: 2 }} />
                                <List sx={{
                                    '> li': {
                                        padding: "2px 5px",
                                        '> a, > .MuiListItemButton-root' : {
                                            borderRadius: "5px",
                                            "span": {fontWeight: "300"},
                                            '&:hover span' : {fontWeight: "400"}
                                        }
                                    },
                                    '.Mui-selected' : {
                                        color: blue[600],
                                        'span' : {
                                            fontWeight: '500'
                                        }
                                    }
                                }}>
                                {
                                    menuLinks?.map((item, idx) => <ListItem key={`${idx}-menulinks`} disablePadding>
                                        { item.link && <ListItemButton dense selected={location.pathname.indexOf(item.link) > -1} component={RouterLink} to={item.link}>
                                            { item?.icon }
                                            <ListItemText primary={item.text} />
                                        </ListItemButton> }
                                        { item.action && <ListItemButton dense onClick={item.action}>
                                            { item?.icon }
                                            <ListItemText primary={item.text} />
                                        </ListItemButton> }
                                    </ListItem>)
                                }
                                </List>
                            </Box>
                        </Drawer>
                        </>}
                    </> }
				</Toolbar>
			</AppBar>
			<Toolbar />
            <Box sx={{ backgroundColor: "#E7EBF0" }}>
                <Box
                    component="main"
                    sx={{
                        pt: "30px",
                        pb: "20px",
                        width: { md: `${width}px` },
                        margin: { xs: "0 1rem", md: "0 auto" },
						minHeight: `calc(100vh - ${hasFooter ? 104 : 64}px)`,
                    }}
                >
                { content || <>
                    { token && <>
                        { !userDetails && <Loading /> }
                        { userDetails && <Outlet /> }
                    </>}
                    { !token && <Outlet /> }
                </> }
                </Box>
                { hasFooter && <Box
                    component="footer"
                    sx={{
                        bgcolor: theme.palette.footer.background,
                        color: theme.palette.footer.foreground,
                        lineHeight: "20px",
                        padding: "10px",
                        fontSize: "0.75rem",
                        "& > a": {
                            color: theme.palette.footer.foreground
                        }
                    }}
                >
                    { setting?.name } © P. IVA { setting?.legals?.vatnumber } | <Link sx={{ textDecorationColor: theme.palette.header.foreground }} target="_blank" href="/privacy-policy/">Privacy</Link> | <Link sx={{ textDecorationColor: theme.palette.header.foreground }} target="_blank" href="/cookie-policy/">Cookie</Link>
                </Box> }
            </Box>
            <CookieConsent />
		</Box>
	);
}

export default Layout;